import React from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonSlide,
} from '@ionic/react';
import styled from 'styled-components';
import { send, cash } from 'ionicons/icons';
import { Menu, Button } from './Menu';

const Slide = styled(IonSlide)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

const Total = styled.div<{
  income: boolean;
}>`
  & > span {
    margin-left: 5px;
    font-size: 12px;
  }

  & > h1 {
    display: inline-block;
    margin: 0;
    color: ${props => (props.income ? '#0ec254' : '#d33939')};
  }
`;

const Title = styled.h1`
  padding-left: 16px;
  text-align: left;
`;

export const Page: React.FC<{
  title: string;
}> = ({ title }) => {
  const [disabled, setDisabled] = React.useState(false);

  const count = new Array(30).fill(0);

  return (
    <Slide>
      <Title>{title}</Title>
      <Content>
        <IonList>
          {count.map((_, i) => {
            return (
              <IonItem key={i}>
                <IonLabel>
                  <p>09/10 - 14/10</p>
                  <h2>FVP 09/2019</h2>
                  <p>Uri za luty</p>
                </IonLabel>
                <Total income={true}>
                  <h1>1 200</h1>
                  <span>pln</span>
                </Total>
                <Menu>
                  <Button
                    icon={send}
                    disabled={disabled}
                    onClick={() => {
                      setDisabled(true);
                    }}
                  />
                  <Button icon={cash} />
                </Menu>
              </IonItem>
            );
          })}
        </IonList>
      </Content>
    </Slide>
  );
};
