import React from 'react';
import { IonSlides, IonContent } from '@ionic/react';
import styled from 'styled-components';

import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';

import { Page } from './Page';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
};

const Container = styled.div`
  flex: 1;
  height: 100%;
`;

const Slides = styled(IonSlides)`
  height: 100%;
`;

const App: React.FC = () => {
  return (
    <Container>
      <IonContent>
        <Slides pager={true} options={slideOpts}>
          <Page title="Taxes"/>
          <Page title="Invoices"/>
          <Page title="Socials"/>
        </Slides>
      </IonContent>
    </Container>
  );
};

export default App;
